export const EXTERNAL_LINK_PARAMS = {
  utm_source: 'tastenetwork',
};

export const addToPath = (url: string | URL, path: string): URL => {
  const urlCopy = new URL(url);

  if (path.startsWith('/') && urlCopy.pathname.endsWith('/')) {
    urlCopy.pathname = `${urlCopy.pathname.slice(0, -1)}${path}`;
  } else if (!path.startsWith('/') && !urlCopy.pathname.endsWith('/')) {
    urlCopy.pathname += `/${path}`;
  } else {
    urlCopy.pathname += path;
  }

  return urlCopy;
};

export const addQueryParams = (
  url: string | URL,
  params: Record<string, string>
): URL => {
  const urlCopy = new URL(url);

  for (const [key, value] of Object.entries(params)) {
    urlCopy.searchParams.set(key, value);
  }

  return urlCopy;
};

// Get the current URL without query parameters
export const getBaseUrl = () => {
  const url = new URL(window.location.href);
  const baseUrl = `${url.protocol}//${url.host}${url.pathname}`;
  return baseUrl;
};

// Get only the root URL (protocol and host)
export const getRootUrl = () => {
  const url = new URL(window.location.href);
  const rootUrl = `${url.protocol}//${url.host}`;
  return rootUrl;
};

export const isValidUrl = (urlString: string): boolean => {
  try {
    new URL(urlString);
    return true;
  } catch (e) {
    return false;
  }
};
